export default {
  rick: {
    id: 'rickUserId',
    uid: 'rickUserUid',
    lastLogin: 'Sanchez',
    name: 'Pickle Rick',
    displayName: 'Rick the Pickle Sanchez',
    roles: []
  },
  morty: {
    id: 'mortyUserId',
    uid: 'mortyUserUid',
    lastLogin: 'Sanchez',
    name: 'Evil Morty',
    displayName: 'Morty you idiot',
    roles: []
  }
}









































import { Component, Vue, Emit } from 'vue-property-decorator'
import { BlipChange } from '@/types/domain'
import { VForm } from '@/types/misc'

@Component({
  computed: {
    change () {
      return {
        date: this.date,
        newLevel: this.levels.indexOf(this.level),
        text: this.changeText
      }
    },
    levels () {
      return this.$store.getters['blips/meta'].levels
    },
    form () {
      return this.$refs.form as VForm
    }
  }
})

export default class NewChange extends Vue {
  valid = false
  dialog = true
  level = ''
  date: string = new Date().toISOString().slice(0, 7)
  changeText = ''
  // computed
  change: BlipChange
  levels: string[]
  form: VForm

  @Emit()
  submit () : BlipChange {
    if (this.form.validate()) {
      this.$store.dispatch('intro/event', 'blip-history-submit')
      setTimeout(() => this.reset(), 100)
      return this.change
    }
  }

  @Emit()
  cancel () : BlipChange {
    setTimeout(() => this.reset(), 100)
    return this.change
  }

  reset () : void {
    this.level = null
    this.date = new Date().toISOString().slice(0, 7)
    this.changeText = null
  }
}

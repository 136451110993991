







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  computed: {
    errorMessage () {
      switch (this.errorCode) {
        case '404':
          return 'No radar found at this location :('
        default:
          return 'Unspecified error (⊙＿⊙\')'
      }
    }
  }
})
export default class App extends Vue {
  @Prop({ default: '' })
  errorCode: string

  // computed
  errorMessage: string
}

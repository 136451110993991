






































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters('intro', [
      'steps'
    ])
  }
})
export default class App extends Vue {
  // computed
  steps: any[]

  mounted () : void {
    this.$store.dispatch('intro/setTourObject', this) // make sure the vuex statemachine has access to to the $tours object
  }
}














import { Component, Vue } from 'vue-property-decorator'
import appConfig from '@/config'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters('blips', [
      'isLoading'
    ])
  }
})
export default class App extends Vue {
  darkMode: boolean = appConfig.theme.dark
  // computed
  isLoading: boolean
}





























import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { Blip, Meta, User } from '@/types/domain'
import NewBlip from './NewBlip.vue'
import { SkillradarChart, SkillradarOptions, SkillradarData } from '@/lib/skillradar'
import appConfig from '@/config'

@Component({
  components: {
    NewBlip
  },
  computed: {
    ...mapGetters('blips', [
      'meta', 'isLoading', 'isLoaded', 'ownerId', 'aliasOrId'
    ]),
    ...mapGetters('user', [
      'user', 'userCanEdit'
    ]),
    blips () {
      return this.$store.getters['blips/blipsWithIndex']
        .map(b => {
          b.detailsUrl = `/#/@${this.radarId}/history?q=${b.title}`
          return b
        })
    }
  }
})
export default class Radar3 extends Vue {
  @Prop({ default: '' })
  radarId: string

  chart: SkillradarChart
  darkMode: boolean = appConfig.theme.dark

  // computed
  blips: Blip[]
  meta: Meta
  user: User
  isLoaded: boolean
  isLoading: boolean
  ownerId: string
  userCanEdit: boolean
  aliasOrId: string

  radarConfig: SkillradarOptions = {
    radius: 300,
    dark: this.darkMode
  }

  constructor () {
    super()
    this.chart = new SkillradarChart(this.radarConfig)
  }

  public renderChart () : void {
    const data: SkillradarData = {
      items: this.blips,
      levels: this.meta.levels,
      categories: this.meta.categories
    }
    // Call function to draw the Radar chart
    // Will expect that data is in %'s
    this.chart.drawLegend('#legendeast', data, (blip: Blip) => blip.category < 2, 'down')
    this.chart.drawLegend('#legendwest', data, (blip: Blip) => blip.category >= 2, 'up')
    this.chart.drawLegend('#legendeast-small', data, (blip: Blip) => blip.category < 2, 'down')
    this.chart.drawLegend('#legendwest-small', data, (blip: Blip) => blip.category >= 2, 'up')
    this.chart.drawChart('#radarchart', data)
  }

  mounted () : void {
    if (this.isLoaded) {
      this.renderChart()
    }
    if (!this.isLoading) {
      this.$store.dispatch('blips/getRadarLazy', this.radarId)
    }
    this.$store.dispatch('intro/event', 'radar-loaded')
    if (this.userCanEdit) {
      this.$store.dispatch('intro/event', 'radar-editable')
    }
  }

  @Watch('blips')
  onBlipsChanged () : void {
    this.renderChart()
  }

  @Watch('meta')
  onMetaChanged () : void {
    this.renderChart()
  }

  @Watch('userCanEdit')
  onUserChange () : void {
  // fire on user change if radar was load prior to login
    if (this.userCanEdit) {
      this.$store.dispatch('intro/event', 'radar-editable')
    }
  }

  @Watch('isLoading')
  onDoneLoading (_oldValue: boolean, newValue: boolean) : void {
    if (newValue === true) {
      this.$store.dispatch('blips/getRadarLazy', this.radarId)
    }
  }
}

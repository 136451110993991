





































































































































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import TOML, { JsonMap } from '@iarna/toml'
import { mapGetters } from 'vuex'
import { Blip, Meta } from '@/types/domain'

function saveAs (filename, text) : void {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

function stripIds (blip) : Blip {
  blip.changes.map(c => {
    delete c.id
    return c
  })
  delete blip.id
  return blip
}

@Component({
  computed: {
    ...mapGetters('blips', [
      'meta', 'radarAlias', 'radarId'
    ]),
    ...mapGetters('user', [
      'userCanEdit'
    ]),
    blipsClean () {
      return this.$store.getters['blips/blipsClean']
        .map(stripIds)
    }
  },
  watch: {
    blipsClean () {
      this.reload()
    },
    meta () {
      this.reload()
    }
  }
})
export default class Settings extends Vue {
  // local data
  contentToml = ''
  uploadFile = ''
  rules: Record<string, unknown> = {
    level: [(val : string) => (val || '').length > 0 || 'Level name cannot be empty'],
    category: [(val : string) => (val || '').length > 0 || 'Category name cannot be empty'],
    title: [(val : string) => (val || '').length > 0 || 'Title cannot be empty'],
    alias: [(val : string) => this.aliasAvailable(val) || 'Alias must be unique'] // TODO: implement uniqueness check
  }

  tmpLevels: string[] = []
  tmpLevelsDirty = false
  tmpCategories: string[] = []
  tmpCategoriesDirty = false
  tmpTitle = ''
  tmpTitleDirty = false
  tmpAlias = ''
  tmpAliasDirty = false
  tmpAliasErrors: string[] = []

  // computed
  meta: Meta
  blipsClean: Blip[]
  userCanEdit: boolean
  radarAlias: string
  radarId: string

  generateToml () : void {
    const obj: Record<string, unknown> = {
      meta: this.meta,
      blips: this.blipsClean
    }
    const str = TOML.stringify(obj as JsonMap) as string
    this.contentToml = str
  }

  // move content from view to vuex
  loadContent () : void {
    try {
      const obj = TOML.parse(this.contentToml)
      this.$store.dispatch('blips/setMeta', obj.meta)
      this.$store.dispatch('blips/setBlips', obj.blips)
      this.$store.dispatch('comm/showSnackbar', 'updated local blips + config')
    } catch (e) {
      console.error('Error occured trying to decompress content', e) // eslint-disable-line no-console
      this.$store.dispatch('comm/showSnackbar', 'error while trying to read uploaded file')
    }
  }

  uploadToml (file : Blob) : void {
    const reader = new FileReader()
    if (file) {
      reader.addEventListener('load', () => {
        this.contentToml = reader.result.toString()
        this.$store.dispatch('comm/showSnackbar', 'file upload successful')
        this.loadContent()
      }, false)
      reader.readAsText(file)
    }
  }

  downloadToml () : void {
    saveAs(`devradar-${this.meta.title.replace(/[^a-zA-Z0-9 _-]/g, '')}.toml`, this.contentToml)
  }

  saveMeta (type : string) : void {
    let content
    switch (type) {
      case 'categories':
        content = this.tmpCategories
        this.tmpCategoriesDirty = false
        break
      case 'levels':
        content = this.tmpLevels
        this.tmpLevelsDirty = false
        break
      case 'title':
        content = this.tmpTitle
        this.tmpTitleDirty = false
        break
      default:
        console.error('Unknown type to save to metadata detected:', type) // eslint-disable-line no-console
        return
    }
    const newData = {}
    newData[type] = content
    const meta = Object.assign(this.meta, newData)

    this.$store.dispatch('blips/setMeta', meta)
    this.$store.dispatch('comm/showSnackbar', `updated ${type} metadata`)
  }

  saveAlias () : void {
    this.$store.dispatch('blips/setRadarAlias', { alias: this.tmpAlias, radarId: this.radarId })
    this.$store.dispatch('comm/showSnackbar', `updated your devradar alias to: ${this.tmpAlias}`)
    this.tmpAliasDirty = false
  }

  reload () : void {
    this.tmpLevels = Array.from(this.meta.levels)
    this.tmpCategories = Array.from(this.meta.categories)
    this.tmpTitle = this.meta.title
    this.tmpAlias = this.radarAlias
    this.generateToml()
  }

  aliasAvailable (value: string) : boolean {
    return value !== null
  }

  @Watch('tmpAlias')
  async tmpAliasKeydown (newValue: string) : Promise<void> {
    this.tmpAliasDirty = true
    this.tmpAliasErrors = []
    if (!/^[a-zA-Z0-9-_ ]+$/.test(newValue)) {
      this.tmpAliasErrors.push('Invalid character detected (a-z A-Z 0-9 -_ only)')
    }
    const isAvailable = await this.$store.dispatch('blips/isRadarAliasAvailable', newValue)
    if (!isAvailable) {
      this.tmpAliasErrors.push(`Alias already in use: ${newValue}`)
    }
  }

  @Emit()
  close () : number {
    return 0
  }

  mounted () : void {
    this.reload()
  }

  @Watch('radarAlias')
  radarAliasUpdate () : void {
    this.reload()
  }
}







import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class Logout extends Vue {
  async mounted () : Promise<void> {
    await this.$store.dispatch('user/logout')
    this.$router.replace('/')
  }
}

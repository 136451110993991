











































































import { Component, Vue } from 'vue-property-decorator'
import { User } from '@/types/domain'

@Component({
  computed: {
    userList () {
      return this.$store.getters['user/userList']
    },
    user () {
      return this.$store.getters['user/user']
    }
  }
})
export default class Users extends Vue {
  items: []
  roles: []
  // computed
  user: User
  userList: Record<string, any>

  setRole (index : number, role : string, value : boolean) : void {
    this.userList[index].roles[role] = value
    const targetUser = this.userList[index]
    if (targetUser.uid === this.user.uid && role === 'admin') {
      console.warn('Preventing admin removal for own user') // eslint-disable-line no-console
      this.$store.dispatch('user/getUserList')
    } else {
      this.$store.dispatch('user/setRoles', { targetUser })
    }
  }

  mounted () : void {
    this.$store.dispatch('user/getUserList')
  }
}

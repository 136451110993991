


















































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import MarkdownIt from 'markdown-it'
import appConfig from '@/config'
import { Blip as IBlip, BlipChange, Meta } from '@/types/domain'

const md = new MarkdownIt()
@Component({
  computed: {
    userCanEdit () {
      return this.$store.getters['user/userCanEdit']
    },
    meta () {
      return this.$store.getters['blips/meta']
    }
  }
})
export default class Blip extends Vue {
  isEditMode = false
  tempBlip: IBlip
  isDeleteMode = false
  showChangeDialog = false
  blipForChange: IBlip
  blipTitleCutOff: number = appConfig.blips.titleCutOff
  darkMode: boolean = appConfig.theme.dark

  // computed
  userCanEdit: boolean
  meta: Meta
  @Prop()
  blip: IBlip

  deleteBlip () : void {
    this.$store.dispatch('blips/deleteBlip', this.blip)
  }

  addChange () : void {
    if (!this.userCanEdit) return
    this.$emit('addChange', this.blip.id)
  }

  editBlip () : void {
    this.tempBlip = { ...this.blip }
    this.isEditMode = true
  }

  saveBlip () : void {
    const updatedBlip = this.tempBlip
    updatedBlip.changes = this.blip.changes // update in case changes were deleted
    this.isEditMode = false
    this.$store.dispatch('blips/updateBlip', updatedBlip)
    this.isDeleteMode = false
  }

  cancelEditBlip () : void {
    this.isEditMode = false
    this.isDeleteMode = false
  }

  deleteChange (blip : Blip, change : BlipChange) : void {
    this.$store.dispatch('blips/deleteChange', { blip, change })
  }

  markdown (string = '') : string {
    if (!string || string.length === 0) return ''
    return md.render(string)
  }
}

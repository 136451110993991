

















































































































































import CookieLaw from 'vue-cookie-law'
import { Component, Vue, Watch } from 'vue-property-decorator'
import appConfig from './config'
import { mapGetters } from 'vuex'
import { Meta, User } from '@/types/domain'
import Login from '@/components/app/Login.vue'
import LoadingIndicator from '@/components/app/LoadingIndicator.vue'
import Intro from '@/components/app/Intro.vue'
import Octocat from '@/components/app/Octocat.vue'

@Component({
  computed: {
    ...mapGetters('blips', [
      'meta', 'radarAlias', 'radarId'
    ]),
    ...mapGetters('user', [
      'user'
    ]),
    ...mapGetters('comm', [
      'snackbar'
    ])
  },
  components: { CookieLaw, Login, LoadingIndicator, Intro, Octocat }
})
export default class App extends Vue {
  showNavdrawer = false
  darkMode: boolean = appConfig.theme.dark
  footerEntries: Record<string, unknown>[] = appConfig.footer
  toolbarItemsRouter: Record<string, unknown>[] = []
  toolbarMenuItemsRouter: Record<string, unknown>[] = []
  toolbarItemsStatic: Record<string, unknown>[] = []
  toolbarMenuItemsStatic: Record<string, unknown>[] = []
  loginModalVisible = false

  // computed
  meta: Meta
  radarId: string
  radarAlias: string
  user: User
  snackbar: {
    active: boolean;
    text: string;
  };

  updateToolbarItems () : void {
    const routes = appConfig.routes
      .filter(i => i.validator(this.user))
      .filter(i => {
        if (i.path && i.path.includes(':radarId') && this.radarId.length === 0) {
          return false
        } else {
          return true
        }
      })
      .map(i => {
        if (i.path) {
          (i as any).updatedPath = i.path
            .replace(':radarId', this.radarAlias || this.radarId)
            .replace('/:mode?', '')
        }
        return i
      })
    const navEntries = appConfig.navEntries
      .filter(i => i.validator(this.user))
    this.toolbarItemsRouter = routes
      .filter(i => i.location.includes('toolbar'))
    this.toolbarMenuItemsRouter = routes
      .filter(i => i.location.includes('toolbar-menu'))
    this.toolbarItemsStatic = navEntries
      .filter(i => i.location.includes('toolbar'))
    this.toolbarMenuItemsStatic = navEntries
      .filter(i => i.location.includes('toolbar-menu'))
  }

  handleNavClick (item) : void {
    if (item.url) {
      window.open(item.url, '_blank')
    } else if (item.action) {
      item.action(this)
    }
  }

  mounted () : void {
    this.updateToolbarItems()
  }

  @Watch('radarId')
  @Watch('radarAlias')
  @Watch('user')
  radarIdChange () : void {
    this.updateToolbarItems()
  }
}
